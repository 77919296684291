import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import StyledButton from '../../components/Button';
import ContactFormCom from '../../components/ContactFormCom';
import SingleOffersGallery from '../../components/SingleOffersGallery';
import Offers from '../../components/Offers';
import SEO from '../../components/SEO';

const HeroStyledWrapper = styled.div`
  position: relative;
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 100px;
`;

const HeroStyledHeaders = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

const HeroStyledHeader = styled.h1`
  position: relative;
  z-index: 9997;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.font.size.m};
  font-family: 'Dancing Script', cursive;
  line-height: 2.2rem;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.xxxl};
  }
`;

const HeroStyledImage = styled(GatsbyImage)`
  position: relative;
  height: 30vh;
  width: 100%;
  opacity: 0.8;
`;

const TextStyledWrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 50px;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.desktop} {
    margin: 50px 300px 0 300px;
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const OffersWrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryFloristic};
  padding: 2rem;
  margin: 2rem 0;
  text-align: center;
  position: relative;
`;

const StyledTitle = styled.h2`
  font-weight: 600;
  font-size: ${({ theme }) => theme.font.size.s};
  text-transform: uppercase;
  text-shadow: 1px 1px 2px lightgrey;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const StyledTitleOffers = styled(StyledTitle)`
  padding: 1.5rem 0 0 0;
  text-align: center;
`;

const DescriptionWrapper = styled.div`
  line-height: 2.2rem;
  max-width: 800px;
  margin: 2em auto;
`;

const StyledH3 = styled.h3`
  color: #59388f;
  text-decoration: underline;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const StyledParagraph = styled.p`
  margin: 4rem 0;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Dancing Script', cursive;
  line-height: 4rem;
  font-size: ${({ theme }) => theme.font.size.s};

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

export default function FlorystykaFuneralna({ data }) {
  return (
    <>
      <SEO title="Florystyka funeralna, wieńce pogrzebowe, dekoracje trumien" />
      <HeroStyledWrapper>
        <HeroStyledHeaders>
          <HeroStyledHeader>{data.singleOffer.title}</HeroStyledHeader>
        </HeroStyledHeaders>
        <HeroStyledImage image={data.singleOffer.image.asset.gatsbyImageData} />
      </HeroStyledWrapper>
      <TextStyledWrapper>
        <DescriptionWrapper>
          <StyledParagraph>
            Niegdyś w Rzymie wierzono, że{' '}
            <b>kwiaty są symbolem wieczności, miłości oraz pamięci</b>. Dlatego
            na ceremoniach pogrzebowych bardzo dużą wagę przyciągano do
            kompozycji nagrobnych z kwiatów w otoczce gałązek iglastych. W ten
            sposób zaczęły powstawać pierwsze wieńce rzymskie charakteryzujące
            się formą obręczy z wcześniej wspomnianych gałązek iglastych i
            kwiatów.
          </StyledParagraph>
          <StyledParagraph>
            Dzisiaj natomiast możemy oddać cześć zmarłym nie tylko w formie
            wieńca. Do najczęstszych wiązanek pogrzebowych zaliczamy{' '}
            <b>
              bukiety kondolencyjne, dekoracje urn, trumien, wiązanki na
              florecie, podstawie z krzyża, serca
            </b>{' '}
            i nie tylko. Składają się one z liści zielonych lub podkładów
            świerkowych wypełnionych szerokim doborem gatunków kwiatów. Wiązanki
            na florecie mogą występować w formie okrągłej, łezki lub tzw.
            rogala. Dobór kwiatów zależny jest od upodobań osoby zamawiającej
            lub tego, co najbardziej kochała osoba zmarła.
          </StyledParagraph>
          <StyledParagraph>
            Florystyka funeralna to nie tylko kompozycje związane z pochówkiem.
            To także dekoracje, którymi możemy uwiecznić pamięć naszych bliskich
            dawno po pogrzebie. Mowa tutaj o{' '}
            <b>kompozycjach z kwiatów żywych lub sztucznych</b>,które zdobią
            pomniki przez cały rok. Styl kompozycji uwarunkowany jest już od
            naszego pomysłu a elementy użyte do tego mogą daleko odbiegać od
            typowych wiązanek.
          </StyledParagraph>
          <StyledParagraph>
            W zależności od relacji jaka nas łączyła z osobą zmarłą, w tych
            dekoracjach warto czasem pobawić się formą oraz doborem kwiatów
            dzięki czemu uzyskamy niepowtarzalny efekt. Jako pracownia{' '}
            <b>chętnie doradzamy naszym klientom</b> oraz staramy się ich
            inspirować na kolejne lata.
          </StyledParagraph>
          <StyledH3>OFERUJEMY</StyledH3>
          <StyledList>
            {data.singleOffer.listOfOffers.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </StyledList>
          <ButtonWrapper>
            <StyledButton as={Link} to="/kontakt">
              Skontaktuj się z nami
            </StyledButton>
          </ButtonWrapper>
        </DescriptionWrapper>
      </TextStyledWrapper>
      <OffersWrapper>
        <StyledTitleOffers>nasze realizacje</StyledTitleOffers>
        <SingleOffersGallery offerTitle="Florystyka funeralna" />
        <ButtonWrapper>
          <StyledButton
            className="white"
            as={Link}
            to="/galeria/florystyka-funeralna"
          >
            Zobacz więcej zdjęć
          </StyledButton>
        </ButtonWrapper>
      </OffersWrapper>
      <ContactFormCom />
      <OffersWrapper>
        <StyledTitleOffers>sprawdź nasze inne oferty</StyledTitleOffers>
        <Offers />
      </OffersWrapper>
    </>
  );
}

export const query = graphql`
  query FlorystykaFuneralnaQuery {
    singleOffer: sanityOffer(
      slug: { current: { eq: "florystyka-funeralna" } }
    ) {
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      id
      listOfOffers
      title
      headline
      description
    }
  }
`;
